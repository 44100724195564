import React from 'react';
import './scrollToNext.scss';

interface IProps {
  nextName?: string;
  scrollToId: string;
}

export const ScrollToNext: React.FunctionComponent<IProps> = props => {
  const { nextName, scrollToId } = props;

  const [opacity, setOpacity] = React.useState(1)

  let [scrollToElem, setScrollToElem] = React.useState<HTMLElement>(undefined);
  let observer: IntersectionObserver;
  const element = React.useRef(null);

  React.useEffect(() => {
    setScrollToElem(document.getElementById(scrollToId));

    let increments: number[] = [];
    const numberOfIncrements: number = 40;

    for (let i = 0; i < 1; i += 1 / numberOfIncrements) {
      increments.push(i);
    }
    observer = new IntersectionObserver(
      entries => {
        let entry = entries[0];
        setOpacity(2 - (window.outerHeight - entry.boundingClientRect.bottom - 100) / 100);
      },
      {
        rootMargin: '-200px',
        threshold: increments
      }
    );
    observer.observe(element.current);
    return (() => {
      observer.unobserve(element.current);
    })
  }, [])

  const onClick = () => {
    if (!!scrollToElem)
      window.scrollTo({ top: scrollToElem.offsetTop, behavior: "smooth" });
  }

  return (
    <div className="scroll-to-next" onClick={onClick} ref={element} style={{ opacity: opacity }}>
      <p>{nextName}</p>
    </div>
  )
}