import * as React from 'react'
import * as moment from 'moment';
import './radioShow.scss';
import { IRadioShow } from '../../typings';

interface IProps extends React.Props<HTMLElement> {
  radioShow: IRadioShow;
  id?: any;
}

interface IState {
}

export class RadioShow extends React.Component<IProps, IState>{
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  public elem: HTMLElement;
  static defaultProps = {
  }
  render() {
    const { radioShow, id } = this.props;
    return (
      <a href={`https://www.mixcloud.com${radioShow.mixcloudurl}`} target='_blank' rel='noreferrer noopener' title={radioShow.title}>
        <div className='radio-show list-item' id={id}>
          <div className='radio-show-image list-item-image'>
            <img src={radioShow.imageSmall} alt={radioShow.title} aria-hidden='true'/>
          </div>
          <p className='radio-show-artists'>{radioShow.title}</p>
          <p className='radio-show-station'>{radioShow.station}</p>
          <p className='radio-show-date'><i>{moment(radioShow.date).format('Do MMMM YYYY')}</i></p>
        </div>
      </a>
    )
  }
}