import * as React from 'react'
import * as ReactDOM from 'react-dom'

interface IProps extends React.HTMLProps<HTMLDivElement> {
  rootMargin?: string;
  className?: string;
  once?: boolean;
  threshold?: number;
}

interface IState {
  enteredViewport: boolean;
}

export class ChangeOnEnter extends React.Component<IProps, IState>{
  constructor(props) {
    super(props);
    this.state = {
      enteredViewport: false
    };
  }
  static defaultProps = {
    rootMargin: '-50px',
    once: false,
    threshold: 0.4
  }
  private observer: IntersectionObserver;
  private element: HTMLElement;
  private intersectionRatio: number;
  private isIntersecting: boolean;
  public getElement() { return this.element }
  public getIntersectionRatio() { return this.intersectionRatio };
  public getIsIntersecting() { return this.isIntersecting }
  componentDidMount() {
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        let entry = entries[0];
        if (entry.isIntersecting) {
          if (!this.state.enteredViewport) {
            this.setState({ enteredViewport: true })
          }
          this.intersectionRatio = entry.intersectionRatio;
          this.isIntersecting = true;
          if (this.props.once)
            observer.unobserve(this.element);
        }
        else {
          if (this.state.enteredViewport) {
            this.setState({ enteredViewport: false })
          }
          this.intersectionRatio = 0;
          this.isIntersecting = false;
        }
      },
      {
        rootMargin: this.props.rootMargin,
        threshold: this.props.threshold
      }
    );
    this.observer.observe(this.element);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.element);
  }

  render() {
    const { className, children, once, threshold, rootMargin, ...attrs } = this.props;
    return (
      <div
        className={`change-on-enter${!!className ? ' ' + className : ''}`}
        ref={r => this.element = r}
        data-entered-viewport={this.state.enteredViewport}
        {...attrs}
      >
        {children}
      </div>
    )
  }
}