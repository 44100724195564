import * as React from 'react'

interface IGetIntersectionChildrenArgs {
  element: React.MutableRefObject<any>
  enteredViewport?: boolean
  intersectionRatio?: number
}

interface IGetIntersectionProps {
  rootMargin?: string;
  numberOfIncrements?: number;
  children: (props: IGetIntersectionChildrenArgs) => any
}

const GetIntersection: React.FunctionComponent<IGetIntersectionProps> = (props: IGetIntersectionProps) => {
  const { rootMargin, children, numberOfIncrements } = props;

  const [enteredViewport, setEnteredViewport] = React.useState(false);
  const [intersectionRatio, setIntersectionRatio] = React.useState(0);

  let observer: IntersectionObserver;
  let element = React.useRef(null);

  React.useEffect(() => {
    let increments: number[] = [];
    for (let i = 0; i < 1; i += 1 / numberOfIncrements) {
      increments.push(i);
    }
    if (!!element && !!element.current) {
      observer = new IntersectionObserver(
        (entries) => {
          let entry = entries[0];
          if (entry.isIntersecting) {
            setEnteredViewport(true)
            setIntersectionRatio(entry.intersectionRatio);
          }
          else {
            setEnteredViewport(false)
            setIntersectionRatio(0);
          }
        },
        {
          rootMargin,
          threshold: increments,
        }
      );
      observer.observe(element.current);
      return (() => {
        observer.unobserve(element.current);
      })
    }
  }, [])

  return children({ element, enteredViewport, intersectionRatio });
}

GetIntersection.defaultProps = {
  rootMargin: '5000px',
  numberOfIncrements: 10
}

export default GetIntersection;
