import * as React from 'react'
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout/layout'
import { ChangeOnEnter } from '../components/visual/changeOnEnter';
import FadeDuringEntry from '../components/visual/fadeDuringEntry';
import { ClubNight } from '../components/data/clubNight';
import { Release } from '../components/data/release';
import { ScrollToNext } from '../components/navigation/scrollToNext';
import { IClubNightEdges, IIndexData, IRadioShowEdges, IArtistEdges, IReleaseEdges, IContactData } from '../typings';
import { RadioShow } from '../components/data/radioShow';
import Parallax from '../components/visual/parallax';

import './index.scss';
import SocialLink from '../components/links/socialLink';

interface IProps {
  data?: {
    clubNights: IClubNightEdges
    radioShows: IRadioShowEdges
    artists: IArtistEdges
    releases: IReleaseEdges
    pageData: IIndexData
    contact: IContactData
  };
}

export const IndexPage: React.FunctionComponent<IProps> = (props: IProps) => {
  const { data } = props;
  const { clubNights, radioShows, pageData, releases, contact } = data;
  const { instagram, facebook, email } = contact;

  return (
    <Layout className='page index-page' isIndex={true}>
      <div className='full-screen-section index-top-full-screen-section'>
        <div className='background-image'>
          <Parallax parallaxAmount={-0.2}>
            <img src={require('../assets/brand/index-top-bg.jpg')} alt='hero image' aria-hidden='false'/>
          </Parallax>
        </div>
        <ScrollToNext nextName='' scrollToId="about-section" />
      </div>

      <ChangeOnEnter className='about-section' id='about-section' threshold={0.2}>
        {/* <img src={require('../assets/brand/logo.jpg')} width='100px' /> */}
        <h3>Who are we?</h3>
        <p className='paragraph'>{pageData.intro}</p>
        <div className='socials'>
          <SocialLink service='instagram' href={instagram} />
          <SocialLink service='facebook' href={facebook} />
          <SocialLink service='mail' href={email} />
        </div>
      </ChangeOnEnter>

      <div className='featured-club-night-wrapper'>
        {!!clubNights && clubNights.edges.length &&
          <ClubNight clubNight={clubNights.edges[0].node} id='featured-club-night' isFeature={true} />
        }
      </div>

      <div className="latest-releases-section list-section" id='releases'>
        <div className="list-section-top">
          <FadeDuringEntry>
            <h3>Releases</h3>
          </FadeDuringEntry>
        </div>

        <ChangeOnEnter className="releases" threshold={0.2}>
          {!!releases && releases.edges.length && releases.edges.slice(0, 3).map((release, i) =>
            <Release release={release.node} key={release.node.title + i} />
          )}
        </ChangeOnEnter>
      </div>

      <div className="radio-shows-section list-section">
        <div className="list-section-top">
          <FadeDuringEntry>
            <h3>Latest Radio Shows</h3>
            <Link to='/radioshows'>
              <u><p>All radio shows ></p></u>
            </Link>
          </FadeDuringEntry>
        </div>
        
        <ChangeOnEnter className="radio-shows" threshold={0.2}>
          {!!radioShows && radioShows.edges.length && radioShows.edges.slice(0, 3).map((radioShow, i) =>
            <RadioShow radioShow={radioShow.node} key={radioShow.node.title + i} />
          )}
        </ChangeOnEnter>
      </div>

      <div className='full-screen-section coming-soon-section'>
        {/* <img src={require('../assets/brand/logo.jpg')} width='100px' />
        <br /> */}
        <h3>A lot more content coming soon</h3>

        <div className='socials'>
          <SocialLink service='instagram' href={instagram} />
          <SocialLink service='facebook' href={facebook} />
          <SocialLink service='mail' href={email} />
        </div>
      </div>
    </Layout >
  )
}

export const query = graphql`
query IndexQuery{
  clubNights: allMarkdownRemark(
      filter: { collectionKey: { eq: "clubNight" } },
      sort: { order: DESC, fields: [date] },
    ){
    edges
    {
      node
      {
        title
        date
        location
        collectionKey
        showTitle
        backgroundBig 
        backgroundMedium 
        backgroundSmall 
        rawMarkdownBody
        facebooklink
        ticketslink
        moretba
        relation_artist
        relation_artist_relations{
          title
          imageSmall
        }
        fields
        {
          path
        }
      }
    }
  }
  releases: allMarkdownRemark(
      filter: { collectionKey: { eq: "release" } },
      sort: { order: DESC, fields: [date] },
    ){
    edges
    {
      node
      {
        title
        date
        collectionKey
        imageBig 
        imageMedium 
        imageSmall 
        rawMarkdownBody
        artist

      }
    }
  }
  residents: allMarkdownRemark(
      filter: { collectionKey: { eq: "artist" }, isResident: {eq: true} },
      sort: { order: DESC, fields: [date] },
    ){
    edges
    {
      node
      {
        title
        date
        collectionKey 
        imageSmall 
      }
    }
  }
  radioShows: allMarkdownRemark(
      filter: { collectionKey: { eq: "radioShow" } },
      sort: { order: DESC, fields: [date] },
    ){
    edges
    {
      node
      {
        title
        collectionKey
        mixcloudurl
        station
        date
        imageSmall
        rawMarkdownBody
        fields
        {
          path
        }
      }
    }
  }
  pageData: markdownRemark( name: { eq: "index" } )
  {
    intro
  }
  contact: markdownRemark( name: { eq: "contact" } )
  {
    facebook
    instagram
    email
  }
}`

export default IndexPage
