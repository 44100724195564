import * as React from 'react'

import GetIntersection from './getIntersection';

interface IFadeDuringEntryProps extends React.HTMLProps<HTMLDivElement> {
  rootMargin?: string
  children?: any
  className?: string
  numberOfIncrements?: number
}

const FadeDuringEntry: React.FunctionComponent<IFadeDuringEntryProps> = (props: IFadeDuringEntryProps) => {
  const { children, rootMargin, numberOfIncrements, ...htmlattr } = props;

  return (
    <GetIntersection rootMargin={rootMargin} numberOfIncrements={numberOfIncrements}>
      {({ element, intersectionRatio }) => (
        <div ref={element} style={{ opacity: intersectionRatio }} {...htmlattr}>{children}</div>
      )}
    </GetIntersection>
  )
}

FadeDuringEntry.defaultProps = {
  rootMargin: '-50px',
}

export default FadeDuringEntry;
