import * as React from 'react'
import * as moment from 'moment';
import './release.scss';
import { IRelease } from '../../typings';
import { Link } from '@reach/router';
import { getLink } from '../../helpers/linkHelper';

interface IProps extends React.Props<HTMLElement> {
  release: IRelease;
  id?: any;
}

export const Release: React.FunctionComponent<IProps> = (props: IProps) => {
  const { release, id } = props;
  const isReleased = moment(release.date).isBefore(moment())
  return (
    <Link to={getLink('release', release.title)}>
      <div className='release list-item' id={id}>
        <div className='release-image list-item-image'>
          <img src={release.imageSmall} alt={release.title} aria-hidden='true'/>
        </div>
        <p className='release-name'>{release.title}</p>
        <p className='release-artist'>{release.artist}</p>
        <p className='release-date'>
          <i>
            {isReleased ? 'Released ' : 'Coming '}
            {moment(release.date).format('Do MMMM YYYY')}
          </i>
        </p>
      </div>
    </Link>
  )
}